import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import { useIntl } from "gatsby-plugin-intl"

import getMediaUrl from "utils/getMediaUrl"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import Icon from "components/atoms/Icon"
import Modal from "components/atoms/Modal"
import CloseIcon from "components/atoms/CloseIcon"
import ContactModal from "components/content/ContactModal"

import {
  StyledCarouselWrapper,
  StyledCarouselImage,
  StyledCarouselImageWrapper,
  StyledButtonBack,
  StyledButtonNext,
  StyledIconWrapper,
  StyledViewAngle,
  StyledCrumbsWrapper,
  StyledContactButton,
  StyledCloseButton,
} from "./ImageSlider.styles"

const ImageSlider = ({
  bannerImages,
  selectedId,
  handleCloseModal,
  isIntrinsicHeight,
  isLightbox,
  trackingOptions,
}) => {
  const { formatMessage } = useIntl()
  const carouselRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(selectedId)
  const [isContactModalVisible, setContactModalVisibility] = useState(false)

  const decreaseSlide = () => {
    const {
      carouselStore: { state },
    } = carouselRef.current

    setCurrentSlide(state.currentSlide - 1)
    trackCustomEventTrigger({
      category: trackingOptions.category,
      action: trackingOptions.action,
      label: `${trackingOptions.category} lightbox button(left arrow)`,
    })
  }
  const increaseSlide = () => {
    const {
      carouselStore: { state },
    } = carouselRef.current

    setCurrentSlide(state.currentSlide + 1)
    trackCustomEventTrigger({
      category: trackingOptions.category,
      action: trackingOptions.action,
      label: `${trackingOptions.category} lightbox button(right arrow)`,
    })
  }
  const openContactModal = () => {
    setContactModalVisibility(true)
    trackCustomEventTrigger({
      category: trackingOptions.category,
      action: trackingOptions.action,
      label: `${trackingOptions.category} lightbox button(Contact)`,
    })
  }
  const closeContactModal = () => {
    setContactModalVisibility(false)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.CONTACT,
      action: trackingOptions.action,
      label: `${trackingCategoriesHelper.CONTACT} button(Close)`,
    })
  }

  const handleKeyDown = (event) => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore
    const { key } = event

    if (
      (key === "ArrowRight" || key === "d") &&
      state.currentSlide < bannerImages.length - 1
    ) {
      const targetSlide = state.currentSlide + 1

      carouselStore.setStoreState({
        ...state,
        currentSlide: targetSlide,
      })
      setCurrentSlide(targetSlide)

      trackCustomEventTrigger({
        category: trackingOptions.category,
        action: trackingOptions.action,
        label: `${trackingOptions.category} lightbox keyboard button(right arrow)`,
      })
    }

    if ((key === "ArrowLeft" || key === "a") && state.currentSlide >= 1) {
      const targetSlide = state.currentSlide - 1

      carouselStore.setStoreState({
        ...state,
        currentSlide: targetSlide,
      })
      setCurrentSlide(targetSlide)

      trackCustomEventTrigger({
        category: trackingOptions.category,
        action: trackingOptions.action,
        label: `${trackingOptions.category} lightbox keyboard button(left arrow)`,
      })
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <>
      <StyledCarouselWrapper isIntrinsicHeight={isIntrinsicHeight}>
        <StyledCloseButton onClick={handleCloseModal}>
          <CloseIcon />
        </StyledCloseButton>
        <StyledCrumbsWrapper>
          {currentSlide + 1} <span /> {bannerImages.length}
        </StyledCrumbsWrapper>
        <StyledContactButton onClick={openContactModal}>
          {formatMessage({
            id: "imageSlider.contact",
          })}
        </StyledContactButton>
        <CarouselProvider
          ref={carouselRef}
          naturalSlideWidth={90}
          naturalSlideHeight={60}
          totalSlides={bannerImages.length}
          touchEnabled={false}
          dragEnabled={false}
          currentSlide={selectedId}
          isIntrinsicHeight={isIntrinsicHeight}
        >
          <Slider>
            {bannerImages.map((image, index) => (
              <Slide index={index} key={image.id}>
                <StyledCarouselImageWrapper>
                  <StyledCarouselImage
                    src={getMediaUrl(image.image)}
                    alt={image.id}
                    isLightbox={isLightbox}
                  />
                  {image.viewAngle && (
                    <StyledViewAngle
                      src={getMediaUrl(image.viewAngle)}
                      alt="View angle"
                    />
                  )}
                </StyledCarouselImageWrapper>
              </Slide>
            ))}
          </Slider>
          {bannerImages.length > 1 && (
            <>
              <StyledButtonBack onClick={decreaseSlide}>
                <StyledIconWrapper>
                  <Icon icon="chevronLeft" />
                </StyledIconWrapper>
              </StyledButtonBack>
              <StyledButtonNext onClick={increaseSlide}>
                <StyledIconWrapper>
                  <Icon icon="chevronRight" />
                </StyledIconWrapper>
              </StyledButtonNext>
            </>
          )}
        </CarouselProvider>
      </StyledCarouselWrapper>

      <Modal
        showModal={isContactModalVisible}
        handleCloseModal={closeContactModal}
      >
        <ContactModal handleCancel={closeContactModal} />
      </Modal>
    </>
  )
}
ImageSlider.propTypes = {
  bannerImages: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      id: PropTypes.string,
      viewAngle: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
    })
  ).isRequired,
  selectedId: PropTypes.number,
  handleCloseModal: PropTypes.func,
  isIntrinsicHeight: PropTypes.bool,
  isLightbox: PropTypes.bool,
  trackingOptions: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
  }),
}

ImageSlider.defaultProps = {
  selectedId: 0,
  handleCloseModal: () => {},
  isIntrinsicHeight: false,
  isLightbox: false,
  trackingOptions: null,
}
export default ImageSlider
