import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-css"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"

import Lightbox from "components/atoms/Lightbox"
import ImageSlider from "components/molecules/ImageSlider"
import getMediaUrl from "utils/getMediaUrl"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import sortImageByPosition from "utils/sortImagesByPosition"

import {
  StyledWrapper,
  StyledGalleryItem,
  StyledImageWrapper,
} from "./ProjectGallery.styles"

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 1,
}

const ProjectGallery = ({ sectionData }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const size = useWindowSize()

  const sortedImages = useMemo(() => sortImageByPosition(sectionData), [
    sectionData,
  ])

  const handleOpenModal = (id) => {
    setSelectedImage(id)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_GALLERY,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_GALLERY} link(image)`,
    })
  }

  const handleCloseModal = () => {
    setSelectedImage(null)
  }

  return (
    <>
      <StyledWrapper>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {sortedImages.map((galleryItem, id) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <StyledImageWrapper
              key={galleryItem.id}
              onClick={() => handleOpenModal(id)}
            >
              <StyledGalleryItem
                src={getMediaUrl(galleryItem.image)}
                loading="lazy"
                alt={galleryItem.id}
              />
            </StyledImageWrapper>
          ))}
        </Masonry>
      </StyledWrapper>

      {size.width >= theme.breakpoints.medium && (
        <>
          {Number.isInteger(selectedImage) && (
            <Lightbox
              showModal={Number.isInteger(selectedImage)}
              handleCloseModal={handleCloseModal}
            >
              <ImageSlider
                bannerImages={sortedImages}
                selectedId={selectedImage}
                handleCloseModal={handleCloseModal}
                isIntrinsicHeight
                isLightbox
                trackingOptions={{
                  category: trackingCategoriesHelper.PROJECT_GALLERY,
                  action: "Click",
                }}
              />
            </Lightbox>
          )}
        </>
      )}
    </>
  )
}

ProjectGallery.propTypes = {
  sectionData: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      title: PropTypes.string,
      viewAngle: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
    })
  ).isRequired,
}

export default ProjectGallery
