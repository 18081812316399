const sortImageByPosition = (images) => {
  const notPositioned = images.filter((item) => !item.position)
  const fixedPostioned = images.reduce((acc, value) => {
    if (value.position) {
      acc[value.position - 1] = value
    }

    return acc
  }, Array(images.length).fill(null))

  return fixedPostioned.map((item) => item || notPositioned.shift())
}

export default sortImageByPosition
