import styled, { css } from "styled-components"
import { ButtonBack, ButtonNext } from "pure-react-carousel"
import Button from "components/atoms/Button"
import Image from "components/atoms/Image"

const StyledCarouselWrapper = styled.div`
  width: 56.8rem;
  position: absolute;
  top: calc(50% - 4.8rem);
  left: 50%;
  bottom: auto;
  right: auto;
  transform: translate(-50%, -50%);
  outline: none;

  ${({ theme }) => theme.mq.large} {
    width: 84.8rem;

    /* plugin style override */
    ${({ isIntrinsicHeight }) =>
      isIntrinsicHeight === true &&
      css`
        width: 80vw;
        height: 80vh;

        .carousel {
          width: 100%;
          height: 100%;

          .carousel__slider--horizontal {
            width: 100%;
            height: 100%;

            .carousel__slider-tray-wrap--horizontal {
              width: 100%;
              height: 100%;

              .carousel__slider-tray--horizontal {
                height: 100%;
              }
            }
          }
        }
      `}
  }

  .carousel__slide-focus-ring {
    display: none !important;
  }
`

const StyledCarouselImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain !important;
`

const StyledCarouselImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

const carouselButtonsStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;

  > svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.color.secondary01};
  }
`
const StyledIconWrapper = styled.div`
  ${carouselButtonsStyles}
`

const carouselClickableArea = css`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  background: none;
  background-color: none;
  border: none;
  outline: none;

  &:hover {
    ${StyledIconWrapper} {
      background-color: ${({ theme }) => theme.color.main03};

      > svg {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
`

const StyledButtonBack = styled(ButtonBack)`
  ${carouselClickableArea}
  left: 0;

  ${StyledIconWrapper} {
    left: -2.4rem;
  }
`
const StyledButtonNext = styled(ButtonNext)`
  ${carouselClickableArea}
  right: 0;

  ${StyledIconWrapper} {
    right: -2.4rem;
  }
`

const StyledViewAngle = styled(Image)`
  max-width: 20%;
  max-height: 30%;
  width: 100%;
  height: auto;
  transition: opacity 0.3s;
  position: absolute !important;
  right: 1rem;
  top: 1rem;

  &:hover {
    opacity: 0.2;
  }
`

const StyledCrumbsWrapper = styled.div`
  position: absolute;
  top: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size.s};
  align-items: center;

  > span {
    height: 1px;
    width: 2rem;
    display: block;
    background-color: ${({ theme }) => theme.color.white};
    margin: 0 0.5rem;
  }
`

const StyledContactButton = styled(Button)`
  position: absolute;
  bottom: -6.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: auto;
`

const StyledCloseButton = styled.div`
  position: absolute;
  left: -9.6rem;
  top: 0;
  border: none;
  background: none;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.white};

  &:hover {
    background-color: ${({ theme }) => theme.color.main03};

    > span {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`
export {
  StyledCarouselWrapper,
  StyledCarouselImage,
  StyledCarouselImageWrapper,
  StyledButtonBack,
  StyledButtonNext,
  StyledIconWrapper,
  StyledViewAngle,
  StyledCrumbsWrapper,
  StyledContactButton,
  StyledCloseButton,
}
