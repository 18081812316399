import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { transparentize } from "polished"

import theme from "styles/theme"

const customStyles = {
  content: {
    height: "100%",
    padding: "0",
    borderRadius: "0",
    border: "none",
    background: "transparent",
    zIndex: "102",
    overflow: "visible",
  },
  overlay: {
    zIndex: "101",
    backgroundColor: transparentize(0.2, theme.color.secondary01),
  },
}

const Lightbox = ({ showModal, handleCloseModal, children }) => {
  const handleKeyDown = (event) => {
    const { key } = event

    if (key === "Escape" || key === "Esc" || key === "x") {
      handleCloseModal()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={showModal}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={handleCloseModal}
      >
        {children}
      </Modal>
    </>
  )
}

Lightbox.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
}

export default Lightbox
