import styled from "styled-components"
import Image from "components/atoms/Image"

const StyledGalleryItem = styled(Image)`
  width: 100%;
  height: auto;
  cursor: pointer;
`

const StyledWrapper = styled.div`
  max-width: ${({ theme }) => theme.gridWidth};
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
  margin: 0 auto;

  .my-masonry-grid {
    display: flex;
    margin-left: -3.2rem;
    width: auto;
  }

  .my-masonry-grid_column {
    padding-left: 3.2rem;
    background-clip: padding-box;
  }
`

const StyledImageWrapper = styled.div`
  margin-top: 3.2rem;
`

export { StyledWrapper, StyledGalleryItem, StyledImageWrapper }
