import React from "react"
import { graphql } from "gatsby"
import { isEmptyRedirect } from "utils/redirect"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ProjectGallery from "components/content/Portfolio/ProjectGallery"
import ClientOnly from "components/atoms/ClientOnly"

// TODO add location - link state
const PortfolioGalleryPage = ({
  data: {
    api: { project },
  },
  pageContext: { language },
  location,
}) => {
  const { gallery } = project || {}
  isEmptyRedirect(gallery)

  return (
    <Layout hideFooter isNavStatic language={language}>
      <SEO title="Project gallery" />
      {gallery && (
        <ClientOnly>
          <PortfolioNavigation projectData={project} location={location} />
          <ProjectGallery sectionData={gallery} />
        </ClientOnly>
      )}
    </Layout>
  )
}

PortfolioGalleryPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        gallery: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
            id: PropTypes.string,
            position: PropTypes.number,
            viewAngle: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
          })
        ),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

export const query = graphql`
  query PortfolioGalleryPageData(
    $apiLocale: API_Locale
    $projectSlug: String!
  ) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        id
        name
        slug
        gallery {
          image
          id
          viewAngle
          position
        }

        ...projectTabs
      }
    }
  }
`

export default PortfolioGalleryPage
